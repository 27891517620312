import { render, staticRenderFns } from "./thirdNews.vue?vue&type=template&id=1a31b31d&scoped=true"
var script = {}
import style0 from "./thirdNews.vue?vue&type=style&index=0&id=1a31b31d&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a31b31d",
  null
  
)

export default component.exports